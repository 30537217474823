<template>
  <el-dialog
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="盘点商品信息"
    :visible.sync="show"
    width="900px"
  >
    <div class="content" v-loading="loading">
      <div style="margin-bottom: 10px">
        <el-button type="primary" :loading="loading" @click="outExl"
          >导出盘点信息</el-button
        >
        <el-button type="primary" @click="putShow = true"
          >导入盘点数量</el-button
        >
      </div>
      <el-table :data="tableData" border height="100%" style="width: 100%">
        <el-table-column type="index" width="50" label="序号">
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="goodsName" label="商品" />
        <el-table-column
          show-overflow-tooltip
          prop="goodsBrand"
          label="商品品牌"
        />
        <el-table-column show-overflow-tooltip prop="stock" label="商品名称" />
        <el-table-column
          show-overflow-tooltip
          prop="barNo"
          label="商品条形码"
        />
        <el-table-column
          show-overflow-tooltip
          prop="clazzName1"
          label="商品一级分类"
        />
        <el-table-column
          show-overflow-tooltip
          prop="clazzName2"
          label="商品二级分类"
        />
        <el-table-column show-overflow-tooltip prop="stock" label="库存数量" />
        <el-table-column show-overflow-tooltip prop="unit" label="库存单位" />
      </el-table>
      <pagination
        slot="pagination"
        :totalCount="totalCount"
        :page-size.sync="params.limit"
        :page.sync="params.page"
        @change="getData"
      />
    </div>
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      title="盘点数量导入"
      :visible.sync="putShow"
      width="600px"
    >
      <div>
        <div class="content">
          <div class="tip">上传文件</div>
        </div>
        <el-upload
          drag
          ref="upload"
          :on-change="onchange"
          :show-file-list="true"
          :multiple="false"
          :auto-upload="false"
          accept=".csv,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/zip,application/x-zip,application/x-zip-compressed, application/vnd.ms-excel,.xls,.doc,.docx,application/msword,"
          name="file"
          action="null"
          class="inputs"
        >
          <div @click="clearHandler">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
          </div>
        </el-upload>
        <div>
          <div>备注信息</div>
          <el-input
            class="inputext"
            type="textarea"
            v-model="remark"
            :rows="5"
            placeholder="备注信息"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="putShow = false">取消</el-button>
        <el-button type="primary" :loading="loading" @click="improtExl"
          >确定</el-button
        >
      </span>
      <el-dialog
        append-to-body
        :close-on-click-modal="false"
        title="错误信息"
        :visible.sync="errorVisible"
        width="400px"
      >
        <div class="content" style="max-height: 300px; overflow: auto">
          <p v-for="item in failList" :key="item.id">
            <b>第{{ item.index }}行</b> {{ item.errorMsg }}
          </p>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="errorVisible = false">取消</el-button>
        </span>
      </el-dialog>
    </el-dialog>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  props: ['inventoryNo'],
  data() {
    return {
      file: null,
      loading: false,
      errorVisible: false,
      failList: [],
      putShow: false,
      tableData: [],
      remark: '',
      params: {
        limit: 20,
        page: 1
      },
      totalCount: 0
    }
  },

  mounted() {
    this.getData()
  },

  methods: {
    async getData() {
      try {
        this.loading
        let data = {
          id: this.inventoryNo,
          limit: this.params.limit,
          page: this.params.page
        }
        const res = await this.$api.commodity.getInventoryList(data)
        this.tableData = res.page.list
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
    onchange(file) {
      this.file = file.raw
      console.log(this.file)
    },
    clearHandler() {
      this.$refs.upload.clearFiles()
    },
    async outExl() {
      try {
        this.loading = true
        this.$api.commodity.exportInfoExel(this.inventoryNo)
        this.loading = false
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
    async improtExl() {
      try {
        this.loading = true
        const formData = new FormData()
        formData.append('file', this.file)
        formData.append('remark', this.remark)
        const res = await this.$api.commodity.inventoryImport(
          this.inventoryNo,
          formData
        )
        if (res.errors) {
          const errLength = res.errors.length
          if (errLength) {
            this.errorVisible = true
            this.failList = res.errors
            this.failList.map(itme => {
              itme.index = Number(itme.index) + 1
            })
          }
        } else {
          this.show = false
          this.putShow = false
          this.loading = false
          this.$emit('getData')
        }
      } catch (error) {
        this.loading = false
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  margin: 15px 0;
  p {
    margin: 6px;
  }
}
.file {
  opacity: 0;
  left: -1000px;
  position: absolute;
}
.tip {
  font-size: 14px;
  letter-spacing: 0px;
  color: #666666;
  margin-bottom: 15px;
}
.inputs {
  margin-left: 100px;
}
.inputext {
  margin-left: 100px;
  margin-top: 10px;
  width: 360px;
}
.file-name {
  margin-left: 12px;
  font-size: 14px;
}
</style>
