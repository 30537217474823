<template>
  <el-dialog
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="查看盘点差异"
    :visible.sync="show"
    width="900px"
  >
    <div class="content" v-loading="loading">
      <el-table :data="tableData" border height="100%" style="width: 100%">
        <el-table-column show-overflow-tooltip prop="goodsName" label="商品" />
        <el-table-column
          show-overflow-tooltip
          prop="barNo"
          label="商品条形码"
        />
        <el-table-column
          show-overflow-tooltip
          prop="stockBegin"
          label="盘点时系统库存"
        />
        <el-table-column
          show-overflow-tooltip
          prop="stockFinish"
          label="实盘数量"
        />
        <el-table-column
          show-overflow-tooltip
          prop="differenceCount"
          label="盈亏数量"
        />
        <el-table-column
          show-overflow-tooltip
          prop="differenceCost"
          label="盈亏成本"
        />
        <el-table-column
          show-overflow-tooltip
          prop="differenceAmount"
          label="盈亏金额"
        />
      </el-table>
    </div>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  props: ['inventoryNo'],
  data() {
    return {
      file: null,
      loading: false,
      errorVisible: false,
      tableData: [],
      params: {
        invoiceMethod: '',
        groupId: '',
        invoiceOrder: '',
        remark: ''
      }
    }
  },

  mounted() {
    this.getDeat()
  },

  methods: {
    async getDeat() {
      try {
        this.loading
        const res = await this.$api.commodity.getInventorycy(this.inventoryNo)
        this.tableData = res.page.list
        console.log(this.tableData)
      } catch (error) {
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  margin: 15px 0;
  p {
    margin: 6px;
  }
}
.file {
  opacity: 0;
  left: -1000px;
  position: absolute;
}
.tip {
  font-size: 14px;
  letter-spacing: 0px;
  color: #666666;
  margin-bottom: 15px;
}
.file-name {
  margin-left: 12px;
  font-size: 14px;
}
</style>
