<template>
  <page v-loading="loading">
    <template slot="headerLeft">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="操作人" prop="inventoryUser">
          <el-input v-model="params.inventoryUser" />
        </el-form-item>

        <el-form-item label="状态" prop="state">
          <el-select v-model="params.state" filterable clearable>
            <el-option label="全部" value=" "></el-option>
            <el-option label="完成" value="2"></el-option>
            <el-option label="未完成" value="1"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="开始时间" prop="date">
          <el-date-picker
            v-model="params.date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </el-form-item>
        <el-button type="primary" :loading="loading" @click="outExel">
          导出
        </el-button>
      </el-form>
    </template>

    <div class="table-wrap">
      <el-table :data="tableData" border height="100%" style="width: 100%">
        <el-table-column
          show-overflow-tooltip
          prop="inventoryNo"
          label="盘点单号"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="inventoryTime"
          label="盘点时间"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="inventoryUser"
          label="操作人"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="differenceCount"
          label="盈亏数量"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="differenceAmount"
          label="盈亏金额"
        ></el-table-column>
        <el-table-column show-overflow-tooltip prop="remark" label="备注">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="stateDesc"
          label="状态"
        ></el-table-column>
        <el-table-column width="220" label="操作">
          <template slot-scope="{ row }">
            <el-button @click="lookInfo(row)" type="text" v-if="row.state !== 2"
              >盘点信息</el-button
            >
            <el-button @click="lookDiff(row)" type="text" v-if="row.state !== 1"
              >查看盘点差异</el-button
            >
            <!-- <el-button @click="lookDiff(row)" type="text"
              >查看盘点差异</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />
    <Difference
      v-if="visible"
      :visible.sync="visible"
      :inventoryNo="inventoryNo"
    />
    <Information
      v-if="lookvisible"
      @getData="getData"
      :visible.sync="lookvisible"
      :inventoryNo="inventoryNo"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import Difference from './components/Difference.vue'
import Information from './components/Information.vue'
export default {
  mixins: [watchParamsGetData],
  components: {
    Difference,
    Information
  },
  data() {
    return {
      loading: false,
      visible: false,
      lookvisible: false,
      tableData: [],
      salesCount: '',
      salesAmount: '',
      inventoryNo: '',
      show: false,
      params: {
        limit: 20,
        page: 1
      },
      totalCount: 0,
      ids: []
    }
  },

  created() {
    this.getData()
  },
  methods: {
    async getData(query) {
      try {
        this.loading = true
        let params = { ...this.params }
        if (params.date && params.date.length) {
          params.createTimeEnd = this.$utils.dateFormat(
            params.date[1],
            'YYYY-MM-DD HH:mm:ss'
          )
          params.createTimeStart = this.$utils.dateFormat(
            params.date[0],
            'YYYY-MM-DD HH:mm:ss'
          )
        } else {
          params.createTimeEnd = undefined
          params.createTimeStart = undefined
        }
        if (query) {
          params.limit = 20
          params.page = 1
        }
        const res = await this.$api.commodity.getInventory(params)
        if (res.page && res.page.list) {
          this.tableData = res.page.list.map(item => {
            return item
          })
        }
        this.totalCount = res.page.totalCount
        this.salesCount = res.salesCount
        this.salesAmount = res.salesAmount
      } catch (err) {
      } finally {
        this.loading = false
      }
    },
    async outExel() {
      try {
        this.loading = true
        let params = { ...this.params }
        if (params.date && params.date.length) {
          params.createTimeEnd = this.$utils.dateFormat(
            params.date[1],
            'YYYY-MM-DD HH:mm:ss'
          )
          params.createTimeStart = this.$utils.dateFormat(
            params.date[0],
            'YYYY-MM-DD HH:mm:ss'
          )
        } else {
          params.createTimeEnd = undefined
          params.createTimeStart = undefined
        }
        await this.$api.commodity.exportInventoryListExel(params)
        this.loading = false
      } catch (error) {
      } finally {
        this.loading = false
      }
      // exportGoodExel
    },
    lookDiff(row) {
      this.visible = true
      this.inventoryNo = row.inventoryNo
    },

    lookInfo(row) {
      this.lookvisible = true
      this.inventoryNo = row.inventoryNo
    }
  }
}
</script>

<style scoped lang="scss">
.conter-div {
  margin-top: 10px;
  span {
    margin-right: 20px;
  }
}
</style>
